// placeholder specific scss
@import 'forms/labels';
@import 'forms/form-control';
@import 'forms/input-group';
@import 'forms/form-check';
@import 'forms/floating-labels';

// Custom third party form plugin scss

/* Textarea with Counter */
.textarea-counter-value {
  background-color: $primary;
  color: $white;
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

//  Number Input style
.btn.disabled-max-min,
.btn.disabled-max-min:focus,
.btn.disabled-max-min:active {
  background-color: rgba($black, 0.5) !important;
  cursor: default;
}

// disabled number input
.bootstrap-touchspin,
.bootstrap-touchspin {
  &.disabled-touchspin {
    .bootstrap-touchspin-down,
    .bootstrap-touchspin-up {
      border-color: transparent !important;
    }
  }
}

/* Number Type Input Box Scss for - Remove arrow on hover */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

// Date & Time Picker - Form Control Bg color
.picker__input {
  &.form-control {
    background-color: $white;
  }
}

// Autofill style
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
  -webkit-text-fill-color: $body-color !important;
}

.disabled-forms{
  input,
  button,
  select,
  textarea,
  checkbox,
  radio,
  optgroup,
  option {
    cursor: 'not-allowed';
    pointer-events: none;
    opacity: 0.5;
  }
}

.disabled-field {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}