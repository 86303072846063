.registered-validator-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .registered-validator-card__name-and-input {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;

    .benefit-validator-email {
      max-width: 16.66rem;
      min-width: 11.13rem;
      width: 100%;
    }
  }

  @media (max-width: 835px) {
    flex-wrap: wrap;
    .registered-validator-card__name-and-input {
      flex-wrap: nowrap;
      .benefit-validator-email {
        max-width: none;
      }
    }
  }

  @media (max-width: 576px) {
    .registered-validator-card__name-and-input {
      flex-wrap: wrap;
    }    
  }
}

.manage-validator-buttons__container {
  display: flex;
  gap: 1rem;
  width: 100%;

  .manage-validator-button {
    width: fit-content;
    padding-right: 2rem;
    padding-left: 2rem;
    white-space: nowrap;
  }

  @media (max-width: 576px) {
    .manage-validator-button {
      width: 100%;
    }
  }

  @media (min-width: 835px) {
    justify-content: flex-end;
  }
}