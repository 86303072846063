$condition-card: "condition-card";
$condition-card-body: "#{$condition-card}-body";

.#{$condition-card} {
  @extend .m-0;
  @extend .rounded-1;

  .#{$condition-card-body} {
    @extend .p-75;

    .#{$condition-card-body}-row {
      @extend .d-flex;

      > *:nth-child(odd) {
        padding-right: 0.375rem;
      }

      > *:nth-child(even) {
        padding-left: 0.375rem;
      }
    }
  }
}
