@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';
@import './base//typographies.scss';
@import './base/core/inputs/inputs';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.colorDarkCustomized {
    color: #FFFFFF !important;

    svg {
        color: #FFFFFF !important;
    }
}

.colorWhiteCustomized {
    color: #FFFFFF !important;
}

.backgroundCustomized {
    background-color: #2b2d31 !important;
}

.customizedColorsIntoBoxCustomizedDarkMode {
    color: #FFFFFF !important;
    background-color: #404249 !important;
}

.customizedColorsIntoBoxCustomizedDarkMode:hover {
    color: #FFFFFF !important;
    background-color: #36373d !important;
}

.ButtonHoverCustomized {
    color: #FFFFFF !important;
    background-color: #404249 !important;
}

.ButtonHoverCustomized:hover {
    color: #FFFFFF !important;
    background-color: #36373d !important;
}

.dropdownItemCustomized {
    color: #ffffff !important;
    background-color: #2b2d31 !important;
}

.dropdownItemCustomized:hover {
    color: #FFFFFF !important;
    background-color: #36373d !important;
}


