// Divider

@mixin divider-line($color: $border-color, $style: solid) {
  border-style: $style;
  border-color: $color;
  border-width: 0 0 1px 0;
}

@mixin divider-line-breakpoints($breakpoint: md, $size: 1px) {
  @include media-breakpoint-up($breakpoint) {
    border-width: 0 $size 0 0;
  }

  @include media-breakpoint-down($breakpoint) {
    border-width: $size 0 0 0;
  }
}

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;

  // divider text display prop

  .divider-text {
    position: relative;
    display: inline-block;
    font-size: 0.9375rem;
    padding: 0 1rem;

    // divider icon font size

    i,
    svg {
      height: 1rem;
      width: 1rem;
      font-size: 1rem;
    }

    // divider border

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      border-top: 1px solid $border-color;
    }

    // divider spacing
    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

  // divider positions config

  &.divider-start {
    .divider-text {
      float: left;
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
  &.divider-start-center {
    .divider-text {
      left: -25%;
    }
  }

  &.divider-end {
    .divider-text {
      float: right;
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
  &.divider-end-center {
    .divider-text {
      right: -25%;
    }
  }

  // divider styles

  &.divider-dotted {
    .divider-text {
      &:before,
      &:after {
        border-style: dotted;
        border-width: 1px;
        border-top-width: 0;
        border-color: $border-color;
      }
    }
  }
  &.divider-dashed {
    .divider-text {
      &:before,
      &:after {
        border-style: dashed;
        border-width: 1px;
        border-top-width: 0;
        border-color: $border-color;
      }
    }
  }
}

.divider-line {
  @include divider-line();

  &.divider-line-lg {
    @include divider-line-breakpoints(lg);
  }
  &.divider-line-md {
    @include divider-line-breakpoints(md);
  }
  &.divider-line-sm {
    @include divider-line-breakpoints(sm);
  }
}
