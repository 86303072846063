.radio-checkmark[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: $grayGotokens;
  width: 25px;
  height: 25px;
  border: 1px solid $grayGotokens;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.radio-checkmark[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: $darkGotokens;
}

.radio-checkmark[type="checkbox"]:checked::before {
  transform: scale(1);
}
